<script>
import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";
import {mapState} from "vuex";

import {required, email} from "vuelidate/lib/validators";
import LanguageSelector from "../../../components/language-selector.vue";

/**
 * Login component
 */
export default {
    components: {LanguageSelector},
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
            authError: null,
            tryingToLogIn: false,
            isAuthError: false,
        };
    },
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        },
    },
    computed: {
        ...mapState("authfack", ["status"]),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                // empty
            } else {
                // if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                this.tryingToLogIn = true;
                // Reset the authError if it existed.
                this.authError = null;
                return (
                    this.logIn({
                        email: this.email,
                        password: this.password,
                    })
                        // eslint-disable-next-line no-unused-vars
                        .then((response) => {
                            if (response.two_factor_authentication) {
                                this.$router.push({name: 'login-two-factor-auth-email-sent'});
                            } else {
                                this.tryingToLogIn = false;
                                this.isAuthError = false;
                                // Redirect to the originally requested page, or to the home page
                                this.$store.dispatch('credit/fetchBalance');
                                this.$store.dispatch('message/fetchList');
                                this.$router.push(
                                    this.$route.query.redirectFrom || {
                                        name: "profile",
                                    }
                                );
                            }
                        })
                        .catch((error) => {
                            this.tryingToLogIn = false;
                            this.authError = error?.response?.data?.errorMSG || error;
                            this.isAuthError = true;
                        })
                );
                /*} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
                  const { email, password } = this;
                  if (email && password) {
                    this.login({
                      email,
                      password,
                    })
                  }
                }*/
            }
        },
    },

    created() {
        document.title = this.setTitle(this.$t('login.log_in'));
    }
};
</script>

<template>
    <div class="authentication-bg min-vh-100">
        <div class="bg-overlay bg-white"></div>
        <div class="container">
            <div class="d-flex flex-column min-vh-100 px-3 pt-4">
                <div class="row justify-content-center my-auto">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="text-center py-5">
                            <div class="mb-4 mb-md-5">
                                <div class="d-flex align-items-center justify-content-center">
                                    <router-link class="d-block auth-logo" to="/">
                                        <img
                                            alt=""
                                            class="auth-logo-dark"
                                            height="22"
                                            src="@/assets/images/logo-dark.png"
                                        />
                                        <img
                                            alt=""
                                            class="auth-logo-light"
                                            height="22"
                                            src="@/assets/images/logo-light.png"
                                        />
                                    </router-link>
                                    <language-selector :icon-btn="true"></language-selector>
                                </div>
                            </div>
                            <div class="mb-4">
                                <h5>{{ $t('login.welcome') }}</h5>
                                <p>{{ $t('login.sign_in_to_continue') }}</p>
                            </div>
                            <b-alert
                                v-model="isAuthError"
                                class="mt-3"
                                dismissible
                                variant="danger"
                            >{{ authError }}
                            </b-alert
                            >
                            <div
                                v-if="notification.message"
                                :class="'alert ' + notification.type"
                            >
                                {{ notification.message }}
                            </div>
                            <b-spinner variant="primary" v-if="tryingToLogIn"></b-spinner>
                            <form v-else @submit.prevent="tryToLogIn">
                                <div class="form-floating form-floating-custom mb-3">
                                    <input
                                        id="input-email"
                                        v-model="email"
                                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                                        class="form-control"
                                        placeholder="Enter User Name"
                                        type="text"
                                    />
                                    <label for="input-email">{{ $t('login.email') }}</label>
                                    <div class="form-floating-icon">
                                        <i class="uil uil-envelope-alt"></i>
                                    </div>
                                    <div
                                        v-if="submitted && $v.email.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!$v.email.required">{{
                                                $t('validate.required', {item: 'Email'})
                                            }}</span>
                                        <span v-if="!$v.email.email"
                                        >{{ $t('validate.email') }}</span
                                        >
                                    </div>
                                </div>
                                <div class="form-floating form-floating-custom mb-3">
                                    <input
                                        id="input-password"
                                        v-model="password"
                                        :class="{ 'is-invalid': submitted && $v.password.$error }"
                                        class="form-control"
                                        placeholder="Enter Password"
                                        type="password"
                                    />
                                    <label for="input-password">{{ $t('login.password') }}</label>
                                    <div class="form-floating-icon">
                                        <i class="uil uil-padlock"></i>
                                    </div>
                                    <div
                                        v-if="submitted && !$v.password.required"
                                        class="invalid-feedback"
                                    >
                                        {{ $t('validate.required', {item: 'Password'}) }}
                                    </div>
                                </div>

                                <div v-if="false" class="form-check form-check-info font-size-16">
                                    <input
                                        id="remember-check"
                                        class="form-check-input"
                                        type="checkbox"
                                    />
                                    <label
                                        class="form-check-label font-size-14"
                                        for="remember-check"
                                    >
                                        {{ $t('login.remember_me') }}
                                    </label>
                                </div>

                                <div class="mt-3">
                                    <button class="btn btn-primary btn-rounded btn-login btn-xl w-100" type="submit">
                                        {{ $t('login.log_in') }}
                                    </button>
                                </div>

                                <div class="mt-4">
                                    <router-link
                                        class="text-muted text-decoration-underline"
                                        to="/forgot-password"
                                    >{{ $t('login.forgot_your_password') }}
                                    </router-link
                                    >
                                </div>
                            </form>
                            <!-- end form -->

                            <!--              <div class="mt-5 text-center text-muted">
                                            <p>
                                                {{ $t('login.dont_have_an_account') }}
                                              <router-link
                                                to="/register"
                                                class="fw-medium text-decoration-underline"
                                              >
                                                  {{ $t('login.signup') }}
                                              </router-link>
                                            </p>
                                          </div>-->
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row">
                    <div class="col-xl-12">
                        <div class="text-center text-muted p-4">
                            <p class="mb-0">
                                &copy; {{ new Date().getFullYear() }} SimpleSMS. {{ $t('login.crafted_with') }}
                                <i class="mdi mdi-heart text-danger"></i> by APPON.hu
                            </p>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </div>
    <!-- end authentication section -->
</template>

<style scoped>
.btn-login {
    font-size: 14px;
    padding: 8px 25px;
    border-radius: 50px;
    transition: 0.3s;
}
</style>